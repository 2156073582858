<template>
    <template v-if="area">
    <section class="aa-area fade-in">
        <div class="aa-area-portada">
            <div class="aa-area-titulo aa-text-h2-5 mt-1 text-start mx-1 fw-500">
                {{area.nombre}}
                <div class="line"></div>
            </div>
            
            <img :src="area.portada" :alt="area.nombre" class="portada-area fade-in" v-on:load="onLoaded" v-show="loaded" />

            <div style="width:100%; height: 327px" v-show="!loaded" class="portada-area">
                <!-- <img src="../../../assets/img/escudo-logo.png" class="mx-auto mt-3" style="width: 33%" /> -->
                <LoadingVue />
            </div>
            
            <div class="aa-area-coordinador text-start ps-1">
                Coordinador/a: {{area.coordinador}}
            </div>
        </div>
        <div class="aa-area-info mb-1">
            <div class="info text-start">
                {{area.descripcion}}
            </div>
            
            <div class="accordion" ref="el" v-if="area.info">
                
                <div class="accordion-group" v-for="info in area.info" :key="info.id">
                    <div class="accordion-menu text-start">
                        {{info.titulo}} <small> Saber +</small>
                    </div>
                    <div class="accordion-content text-start" v-html="info.descripcion"></div>
                </div>

            </div>

        </div>
        <div class="aa-area-departamentos">
            <div class="departamento" v-for="{nombre, id} in departamentos" :key="id" @click="ir(id)">
                <div class="titulo aa-text-h2 text-start ps-1 mb-2 mt-2">
                    {{nombre}}
                </div>
                <div class="arrow me-2">
                    <img src="../../../assets/img/arrowR.svg" alt="Entrar">
                </div>
            </div>
        </div>
        <DescargaDocumento :documentos="area.documentos" :titulo="'Documentos'" />
    </section>
    </template>
    <template v-else>
        <AreaSk />
    </template>
</template>

<script>
import { defineAsyncComponent, ref, watch, watchEffect } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import useAreas from '../composables/useAreas'

export default {
    components: {
        DescargaDocumento: defineAsyncComponent( () => import('../components/Area/DescargaDocumento.vue')),
        LoadingVue: defineAsyncComponent( () => import('../components/Loading.vue') ),
        AreaSk: defineAsyncComponent( () => import('../components/skeleton/AreaSk.vue') )
    },
    setup(){
        const route = useRoute()
        const router = useRouter()
        const { isLoading, detalleArea, areaDetalle, departamentosList, departamentos, el, asignaAnimacion  } = useAreas()
        
        const loaded = ref(false)

        detalleArea(route.params.id)
        departamentosList(route.params.id)
        
        watch(
            () => route.params.id,
            () => {
                if(route.name === 'area'){
                    detalleArea(route.params.id)
                }
            },
            () => departamentosList(route.params.id),
        )

        watchEffect(() => {
            if (el.value) {
                asignaAnimacion()
            }
        })

        return {
            isLoading,
            area: areaDetalle,
            departamentos,
            el,
            ir: (id) => router.push({name:'departamento', params: {id}}),
            onLoaded: () => loaded.value = true,
            loaded
        }

    }
}
</script>

<style lang="sass" scoped>
.aa-area
    $root:&
    
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-titulo
        order: 1
        .line
            background-color: #86e4b9
            display: inline-block
            height: 20px
            left: 0
            position: absolute
            top: 32px
            width: 90%
            z-index: -1

    &-portada
        display: flex
        flex-direction: column
        .portada-area
            order:3
            width: 100%
            margin-bottom: 0px
            @include breakpoint(md)
                height: 400px

    &-coordinador
        order:2
        font-size: 0.8rem
        margin-top: 7px
        margin-bottom: 6px

    &-info
        width: 93%
        font-size: 0.9rem
        background-color: #D8D8D8
        .info
            width: 88%
            background-color: #1AD580
            padding: 1rem 1rem 1rem 1rem
        .info-extra
            margin: 10px 0px 0px 10px
        @include breakpoint(md)
            width: 75%
            position: relative
            bottom: 30px
            .info
                width: 95%
    &-departamentos
        .departamento
            display: flex
            flex-direction: row
            flex-wrap: nowrap
            justify-content: space-between
            align-items: center
            .titulo
                position: relative
                flex: 2
                &::after
                    content: " "
                    position: absolute
                    height: 2px
                    top: 47px
                    width: 80%
                    background-image: linear-gradient(45deg, $base-color, transparent)
                    left: 0px
            .arrow
                img
                    height: 35px
</style>