import { ref } from "vue"
import { gsap } from 'gsap'

import publicApi from "@/api/publicApi"
import { marked } from "marked"
// import { marked } from "marked"
import defaultImg from '@/assets/img/ESCUDO_FRAY.png'

const useAreas = () => {
    const areasList = ref([])
    const isLoading = ref(false)
    const areaDetalle = ref()
    const departamentos = ref()
    const el = ref(null)
    const animations = [];
    
    let tl = gsap.timeline()

    const creaAnima = () => {
        tl.to('.aa-areas-left', {duration: 1, autoAlpha:1},0)
    }

    const cargaAreasListado = async() => {
        try {
            isLoading.value = true
            const { data } = await publicApi.get(`areas?populate[portada][fields][0]=url&fields[0]=nombre&sort[0]=id`)
            if (data){
                isLoading.value = false
                areasList.value = data.data.map(area => {
                    return {
                        id: area.id,
                        nombre: area.attributes.nombre,
                        portada: area.attributes.portada.data.attributes.url
                    }
                })
            }
        } catch (error) {
            console.log(error);
            isLoading.value = false
        }
    }

    const detalleArea = async (id) => {
        try {
            if(!id) return
            isLoading.value = true
            const { data } = await publicApi.get(`areas/${id}?populate[portada][fields][0]=url&populate[coordinador][fields][0]=nombre&populate[info][fields][0]=titulo,descripcion&populate[documentos][fields][0]=url,caption`)
            isLoading.value = false
            
            areaDetalle.value = {
                id: data.data.id,
                nombre: data.data.attributes.nombre,
                descripcion: data.data.attributes.descripcion,
                info: data.data.attributes.info.map( i => {
                    return {titulo:i.titulo, descripcion: marked(i.descripcion), id: i.id}
                }),
                coordinador: data.data.attributes.coordinador.data.attributes.nombre,
                portada: (data.data.attributes.portada.data) ? data.data.attributes.portada.data.attributes.url : defaultImg,
                documentos: (data.data.attributes.documentos) ? data.data.attributes.documentos.data : null
            }


        } catch (error) {
            console.log(error)
        }
    }

    const departamentosList = async (id ) => {
        try{
            if (!id) return
            
            const { data } = await publicApi.get(`departamentos?filters[area]=${id}&populate[portada][fields][0]=url&populate[responsable][fields][0]=nombre`)
            departamentos.value = data.data.map( d => {
                return {
                    id: d.id,
                    nombre: d.attributes.nombre,
                }
            })
        }catch(error){
            console.log(error)
        }
    }


    // Animaciones
    const toggleAnimation = (menu) =>  {
        const selectedReversedState = menu.animation.reversed();
        animations.forEach(animation => animation.reverse());
        menu.animation.reversed(!selectedReversedState);
    }

    const createAnimation = (element) => {
        const menu = element.querySelector(".accordion-menu");
        const box  = element.querySelector(".accordion-content");
        gsap.set(box, { height: "auto"})
        
        const tween = gsap.from(box, { 
            height: 0, 
            duration: 0.5, 
            ease: "power1.inOut",
            reversed: true
        });
        
        menu.animation = tween;
        animations.push(tween);
    }

    const asignaAnimacion = () => {
        const groups = gsap.utils.toArray(".accordion-group");
        const menus = gsap.utils.toArray(".accordion-menu");
        groups.forEach(group => createAnimation(group))
            menus.forEach(menu => {
                menu.addEventListener("click", () => toggleAnimation(menu))
            });
    }

    return {
        cargaAreasListado,
        creaAnima,
        detalleArea,
        departamentosList,
        asignaAnimacion,
        areaDetalle,
        isLoading,
        areasList,
        departamentos,
        el
    }

}


export default useAreas